<template>
  <section class="speaker-detail" v-if="speaker">
    <div class="speaker-detail-name-flex">
      <div
        :style="{
          background: speaker.photoUrl
            ? 'url(' + speaker.photoUrl + ')'
            : 'url(' + require('@/assets/img/user.png') + ')'
        }"
        class="detail-speaker-flex-photo"
      ></div>
      <div class="detail-speaker-flex-name">
        <h4>{{ speaker.name }}</h4>
        <span v-html="speakerDescription"></span>
      </div>
      <img class="img-flag" :src="speakerImage" :alt="speaker.country" />
    </div>
    <div class="detail-speaker-tags-flex">
      <advanced-tags
        :special-tags="speaker.special_categories"
        :content-tags="speaker.categories"
        style-class="speaker-tag-flex-detail"
      />
    </div>
    <div class="speaker-content-detail" v-if="speaker.shortDescription">
      <p v-html="speaker.description"></p>
    </div>
  </section>
</template>

<script>
import AdvancedTags from "@/components/page/items/tags/AdvancedTags";
export default {
  name: "SpeakerBlock",
  components: { AdvancedTags },
  props: {
    speaker: Object
  },
  computed: {
    speakerImage() {
      return require("@/assets/img/flag/" +
        (this.speaker ? this.speaker.countryFlagCode : "russia") +
        ".svg");
    },
    speakerDescription() {
      return this.speaker
        ? this.speaker.shortDescription || this.speaker.description
        : "";
    }
  }
};
</script>

<style scoped></style>
