<template>
  <div :class="styleClass">
    <div class="speaker-tag-left" v-if="specialTags">
      <label>Специализация</label>
      <tags :tags="specialTags" />
    </div>
    <div class="speaker-tag-right" v-if="contentTags">
      <label>Контент</label>
      <tags :tags="contentTags" />
    </div>
  </div>
</template>

<script>
import Tags from "@/components/page/items/tags/Tags";
export default {
  name: "AdvancedTags",
  components: { Tags },
  props: {
    specialTags: Array,
    contentTags: Array,
    styleClass: {
      type: String,
      default: "speaker-tag-flex"
    }
  }
};
</script>

<style scoped></style>
