<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-big">Спикеры</h1>
      <speaker-block :speaker="speaker" />
      <video-slider-block
        block-class="sleder-speaker-detail-video"
        block-head="Видео со спикером"
        :media="media"
      />
    </div>
  </div>
</template>

<script>
import VideoSliderBlock from "@/components/page/media/VideoSliderBlock";
import SpeakerBlock from "@/components/page/speaker/SpeakerBlock";
export default {
  name: "Speaker",
  components: { VideoSliderBlock, SpeakerBlock },
  data() {
    return {
      videos: null,
      speaker: null
    };
  },
  computed: {
    media: {
      get() {
        return this.videos;
      },
      set(n) {
        this.videos = n;
      }
    }
  },
  methods: {
    getSpeakerVideos(name) {
      this.axios
        .get("/video/search", {
          params: { search: name }
        })
        .then(response => {
          this.media = this.getData(response.data);
        });
    },
    getSpeaker() {
      return this.axios
        .get("/speaker/get", {
          params: { id: this.$route.params.id }
        })
        .then(response => {
          this.speaker = response.data;
          return response.data;
        });
    },
    // todo remove below
    getData(d) {
      return d.reduce(
        (acc, curr) =>
          acc.concat({
            tags: curr.category,
            name: curr.title,
            status: curr.subscription ? "По подписке" : "Бесплатно",
            subscription: curr.subscription,
            video: {
              length: new Date(curr.duration * 1000)
                .toISOString()
                .substr(11, 8),
              views: curr.viewsCount,
              vote: curr.rating + "/10",
              id: curr.id,
              poster: curr.coverUrl,
              sources: curr.sources
            }
          }),
        []
      );
    }
  },
  created() {
    this.getSpeaker().then(data => {
      this.getSpeakerVideos(data.name);
    });
  }
};
</script>

<style scoped></style>
